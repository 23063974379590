import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button, Tab, Tabs, CircularProgress, TextField } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { supabase } from '../supabaseClient';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

interface PageContent {
  id: number;
  page_key: string;
  title: string;
  content: string;
  header_image_path?: string;
}

// Add constant for pages that can have header images
const PAGES_WITH_HEADER = ['about', 'aktuelles'];

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link'],
    ['clean']
  ]
};

const Settings: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('aktuelles');
  const [pages, setPages] = useState<PageContent[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadInitialData = async () => {
      await fetchPages();
      setLoading(false);
    };
    loadInitialData();
  }, []);

  useEffect(() => {
    // Reset header image URL when switching tabs
    setHeaderImageUrl(null);
    
    // Only try to load header image for pages that support it
    if (PAGES_WITH_HEADER.includes(selectedTab)) {
      const currentPage = pages.find(p => p.page_key === selectedTab);
      if (currentPage?.header_image_path) {
        const url = supabase.storage
          .from('page-images')
          .getPublicUrl(currentPage.header_image_path).data.publicUrl;
        setHeaderImageUrl(url);
      }
    }
  }, [selectedTab, pages]);

  const fetchPages = async () => {
    try {
      const { data, error } = await supabase
        .from('page_content')
        .select('*');

      if (error) throw error;

      if (data) {
        // Sort pages in a specific order
        const pageOrder = ['aktuelles', 'about', 'datenschutz', 'impressum', 'agb'];
        const sortedData = data.sort((a, b) => {
          return pageOrder.indexOf(a.page_key) - pageOrder.indexOf(b.page_key);
        });
        setPages(sortedData);
      }
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    // Ensure we have the latest data when switching tabs
    fetchPages();
  };

  const handleContentChange = (content: string) => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    setPages(prevPages =>
      prevPages.map(page =>
        page.page_key === selectedTab
          ? { ...page, content }
          : page
      )
    );
  };

  const handleTitleChange = (title: string) => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    setPages(prevPages =>
      prevPages.map(page =>
        page.page_key === selectedTab
          ? { ...page, title }
          : page
      )
    );
  };

  const handleSave = async () => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    setSaving(true);
    const { error } = await supabase
      .from('page_content')
      .update({ 
        content: currentPage.content,
        title: currentPage.title 
      })
      .eq('id', currentPage.id);

    if (error) {
      console.error('Error saving page:', error);
    }
    setSaving(false);
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    const files = event.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];

    try {
      // Delete old image if exists
      if (currentPage.header_image_path) {
        await supabase.storage
          .from('page-images')
          .remove([currentPage.header_image_path]);
      }

      // Upload new image
      const fileName = `${currentPage.id}/${Date.now()}-${file.name}`;
      const { error: uploadError } = await supabase.storage
        .from('page-images')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Update page record
      const { error: updateError } = await supabase
        .from('page_content')
        .update({ header_image_path: fileName })
        .eq('id', currentPage.id);

      if (updateError) throw updateError;

      // Refresh pages
      fetchPages();
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeleteImage = async () => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage?.header_image_path) return;

    try {
      // Delete from storage
      const { error: storageError } = await supabase.storage
        .from('page-images')
        .remove([currentPage.header_image_path]);

      if (storageError) throw storageError;

      // Update page record
      const { error: updateError } = await supabase
        .from('page_content')
        .update({ header_image_path: null })
        .eq('id', currentPage.id);

      if (updateError) throw updateError;

      // Refresh pages
      fetchPages();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const currentPage = pages.find(p => p.page_key === selectedTab);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Einstellungen
      </Typography>

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Seiteninhalt bearbeiten
          </Typography>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ mb: 2 }}
          >
            <Tab label="Aktuelles" value="aktuelles" />
            <Tab label="Über uns" value="about" />
            <Tab label="Datenschutz" value="datenschutz" />
            <Tab label="Impressum" value="impressum" />
            <Tab label="AGB" value="agb" />
          </Tabs>

          {currentPage && (
            <>
              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  label="Seitentitel"
                  variant="outlined"
                  value={currentPage.title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  sx={{ mb: 2 }}
                />
              </Box>

              {PAGES_WITH_HEADER.includes(selectedTab) && (
                <Box sx={{ mb: 3 }}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    Header Bild {headerImageUrl ? 'ändern' : 'hochladen'}
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleImageUpload}
                    />
                  </Button>

                  {headerImageUrl && (
                    <Box sx={{ position: 'relative', mt: 2, maxWidth: 600 }}>
                      <img
                        src={headerImageUrl}
                        alt="Header"
                        style={{ width: '100%', height: 'auto' }}
                      />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: 'white',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                        onClick={handleDeleteImage}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}

              <Box sx={{ mb: 2, '& .ql-editor': { minHeight: '300px' } }}>
                <ReactQuill
                  value={currentPage.content}
                  onChange={handleContentChange}
                  modules={quillModules}
                />
              </Box>

              <Button
                variant="contained"
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? 'Speichern...' : 'Speichern'}
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Settings; 